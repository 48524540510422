@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-image: url("../src/assets/Background.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  font-family: "Roboto", sans-serif;
  cursor: url("../src/assets/cursor.png"), auto;
}
#Welcome {
  background-image: url("../src/assets/Background.png");
}

::-webkit-scrollbar {
  display: none;
}
#Main {
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  /* height: 95vh; */
  height: 100vh;
}

section {
  height: 100%;
  position: relative;
  scroll-snap-align: end start;
  scroll-snap-stop: always;
  overflow-x: hidden;
  overflow: scroll;
  scroll-behavior: smooth;
}

/* -----------Swiper -----------------------*/
@media (max-width: 767px) {
  #Main {
    padding-bottom: 3%;
  }
  section {
    padding-bottom: 6%;
  }
}
